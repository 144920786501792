import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
import { isNumeric } from 'rxjs/util/isNumeric';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DomSanitizer} from '@angular/platform-browser';
import { isNull } from 'util';
import { Canvas, Cell, Columns, Ellipse, Img, Item, Line, PageReference, PdfMakeWrapper, Polyline, Rect, Stack, Table, Toc, Txt, Ul } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { title } from 'process';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

PdfMakeWrapper.setFonts(pdfFonts);

const GET_ALL_ENTRY_REQUEST_BY_COMPANY_ID = environment.Global.API_GET_ALL_REQUEST_BY_COMPANY_ID;
const GET_ALL_ENTRY_REQUEST = environment.Global.API_GET_ALL_REQUEST;
const GET_ALL_REQUEST_INDUCTION = environment.Global.API_GET_ALL_REQUEST_INDUCTION;
const GET_ALL_REQUEST_INDUCTION_BY_ID = environment.Global.API_GET_ALL_REQUEST_INDUCTION_BY_ID;
const GET_BY_ID_COMPANY_WORK_AUTH = environment.Global.API_GET_BY_ID_COMPANY_WORK_AUTH;
const GET_ALL_WORK_AUTHS_FOR_ADMIN = environment.Global.API_GET_ALL_WORK_AUTHS_FOR_ADMIN;
const GET_ALL_COMPANIES = environment.Global.API_GET_ALL_COMPANIES;
const GET_DOCUMENTS_BY_ID = environment.Global.API_GET_DOCUMENTS_BY_ID;
const GET_ALLINDICATOR = environment.Global.API_ALLINDICATOR;
const GET_ALLINDICATOR_BY_COMPANY_ID = environment.Global.API_ALLINDICATOR;
const GET_PERSONAL_STATUS_REPORT = environment.Global.API_GET_PERSONAL_REPORT;
const GET_PERSONAL_BY_COMPANY = environment.Global.API_GET_PERSONAL_RESUMED;
const GET_PERSONAL_STATUS_REPORT_BY_ID = environment.Global.API_GET_PERSONAL_REPORT_BY_ID;

const GET_REPORT_ENTRY_REQUEST = environment.Global.API_REPORT_ENTRY_REQUEST;
const GET_REPORT_REQUEST = environment.Global.API_REPORT_REQUEST;


@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css']
})


export class ReportingComponent extends BaseCrudComponent implements OnInit {
  @ViewChild('thead', {static: true}) thead:ElementRef;
  userInfo;
  option_processes = null;
  option_status = null;
  date_init = null;
  date_end = null;
  list_processes = [{title:'induction_soli', id: 1}, {title:'request_soli', id: 2}, {title:'work_sol', id:3}, {title:'indi_sol', id:4}]//, {title: 'status_per', id: 5}]
  list_status = [{title:'habilitated', id: 1}, {title:'not_habilitated', id: 2}];
  translations;
  isFind = false;
  options_ruc = null;
  options_company = null;
  disabledCompany = false;
  disabledRuc = false;
  companies_list = [];
  ruc_list = [];
  request_selected = false;
  isSkretting = false;
  isClean = false;
  showSelectStatus = false;
  pageSize = 10;
  flag = false;
  fecha_Actual = new Date();
  fecha_sin_formato = this.formatOnlyDate(this.fecha_Actual);
  col_value = 0;
  url_report_ = '';
  row_emp = [];
  page2 = 0;
  pageTotal2 = 0;
  rawdata = [];
  rows = [];
  page = 0;
  pageTota = 0;
  curr_parameter = 0;
  loadingRequired = false;
  row_typejob = [];
  u_obj1 = [];

  dataexcel = [];
  colorCelda = '';


  l = [];
  private workbook: Workbook;
  private lista_titulos;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
    super(srv, modalService, storage, router);
    this.search_fields = ['Requiredby', 'CompanyName', 'WhoRequest', 'Name', 'WorkAuthId', 'namecompany'];
  }


  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
      .toPromise().then((translations) => {
        this.translations = translations;
      });
    await this.storage.get('userinfo').then(x => {
      this.userInfo = x;
      // console.log(this.userInfo)
      if (isNumeric(this.userInfo.logon)) {
        this.isSkretting = false;
      } else {
        this.isSkretting = true;
      }
    });
    this.getCompanies();
    if (this.request_selected) {
      this.disabledCompany = false;
      this.disabledRuc = false;
    } else {
      this.disabledCompany = true;
      this.disabledRuc = true;
    }
    //if(this.isSkretting){
    //  this.list_processes.push({title: 'status_per_re', id: 6})
    //}
  }

  selectRequest() {
    console.log(this.isSkretting);
    console.log('OPCION ELEGIDA', this.option_processes);
    this.request_selected = true;
    this.rows = [];
    this.page = 0;
    this.pageTotal = 0;
    this.disabledCompany = false;
    this.disabledRuc = false;
    this.showSelectStatus = false;
    this.url_report_ = '';
    if (this.isSkretting) {
      if (this.option_processes == 1) {
        this.col_value = 7
      } else if (this.option_processes == 2) {
        this.col_value = 8
      } else if (this.option_processes == 3) {
        this.col_value = 8
      } else if (this.option_processes == 5) {
        this.col_value = 6
        this.showSelectStatus = true;
      } else if (this.option_processes == 6) {
        this.col_value = 6
        this.showSelectStatus = true;
      }
    } else {
      if (this.option_processes == 1) {
        this.col_value = 6
      } else if (this.option_processes == 2) {
        this.col_value = 7
      } else if (this.option_processes == 3) {
        this.col_value = 8
      } else if (this.option_processes == 5) {
        this.col_value = 5
        this.showSelectStatus = true;
      } else if (this.option_processes == 6) {
        this.col_value = 4
        this.showSelectStatus = true;
      }
    }

    if (this.option_processes == 1) {
      this.url_report_ = GET_REPORT_REQUEST;
    } else if (this.option_processes == 2) {
      this.url_report_ = GET_REPORT_ENTRY_REQUEST;
    }
    // if (this.option_processes !== null) {
    //   if (this.options_company !== null || this.options_company != -1) {
    //     this.disabledRuc = true;
    //   }
    //   if (this.options_ruc !== null || this.options_ruc != -1) {
    //     this.disabledCompany = true;
    //   }
    // }


    this.options_ruc = null;
    this.options_company = null;
    this.date_end = null;
    this.date_init = null;
  }

  clearFilters() {

    this.option_processes = null;
    this.options_company = null;
    this.options_ruc = null;
    this.option_status = null;
    this.date_end = null;
    this.date_init = null;
    this.rows = [];
    this.rawdata = [];
    this.isFind = false;
    this.page = 0;
    this.url_report_ = '';
    this.disabledCompany = true;
    this.disabledRuc = true;
  }

  dataToFind() {
    this.isFind = true;

    if (this.option_processes === null) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_all_required, '', 'error');
    }

    if (this.date_end < this.date_init) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_error_date, this.translations.msg_error_date_end, 'error');
    }
    if (this.date_init!== null && this.date_end === null) {
      // this.date_init = null;
      this.isFind = false;
      return Swal.fire(this.translations.msg_should_date_end_select,'','error');
    }
    if (this.date_end!== null && this.date_init === null) {
      // this.date_end = null;
      this.isFind = false;
      return Swal.fire(this.translations.msg_should_date_init_select,'','error');
    }
    this.setUrlToFindData();
  }


  setUrlToFindData() {
    let url = '';
    console.log(this.option_processes)
    console.log(this.userInfo);
    switch (Number(this.option_processes)) {
      case 1:
        console.log('entro aqui 1')
        if (isNumeric(this.userInfo.logon)) {
          url = GET_ALL_REQUEST_INDUCTION_BY_ID + this.userInfo.selectedCompanyId;
          this.isSkretting = false;
        } else {
          url = GET_ALL_REQUEST_INDUCTION;
          this.isSkretting = true;
        }
        break;
      case 2:
        console.log('entro aqui 2')
        if (isNumeric(this.userInfo.logon)) {
          url = GET_ALL_ENTRY_REQUEST_BY_COMPANY_ID + this.userInfo.selectedCompanyId;
          this.isSkretting = false;
        } else {
          this.isSkretting = true;
          url = GET_ALL_ENTRY_REQUEST;
        }
        break;
      case 4:
        console.log('entro aqui 4')
        if (isNumeric(this.userInfo.logon)) {
          url = GET_ALLINDICATOR_BY_COMPANY_ID + '/' + this.userInfo.selectedCompanyId;
          this.isSkretting = false;
        } else {
          this.isSkretting = true;
          url = GET_ALLINDICATOR;
        }
        break;
      case 5:
        console.log('entro aqui 5')
        if (isNumeric(this.userInfo.logon)){
          console.log('entro a logon')
          url = GET_PERSONAL_STATUS_REPORT_BY_ID + this.userInfo.selectedCompanyId;
          this.isSkretting = false;
        } else {
          console.log('no entro a logon')
          url = GET_PERSONAL_STATUS_REPORT;
          this.isSkretting = true;
        }
        break;
      case 6:
          url = GET_PERSONAL_BY_COMPANY;
          this.isSkretting = true;
        break;
      default:
        console.log('entro aqui 3')
        if (isNumeric(this.userInfo.logon)) {
          url = GET_BY_ID_COMPANY_WORK_AUTH + this.userInfo.selectedCompanyId;
          this.isSkretting = false;
        } else {
          url = GET_ALL_WORK_AUTHS_FOR_ADMIN;
          this.isSkretting = true;
        }
        break;
    }
    console.log(url);
    this.isFind = false;
    this.getListFromApi(url);
  }

  GetTrabajadores(cid: any) {
    var qst = this.read_url;
    //this.loadingList = true;

    // if (isNumeric(this.userInfo.logon)) {
      this.read_url = "Employee/GetEmployeesByCompany/" + cid;
    // }
    // else {
    //   this.read_url = "Employee/GetEmployeesAllCompany";
    // }
    var qst = this.read_url;
    //console.log("usuario logoneado:",this.userinfo)
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val != null) {
          //this.rawdata = x.val;
          var rs = x.val;
          this.row_emp = rs;
          //console.log("rs:",rs);

          this.page2 = 1;
          if (Array.isArray(this.row_emp)) {
            this.pageTotal2 = this.row_emp.length;
          }
        } else {
          //Swal.fire('¡Vacio!', 'La consulta no devolvio registros', 'success');
          this.row_emp = [];
          return;
        }
      } else {
        //Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  GetTypeJob() {
    var qst = "ConsultasGenerales/ConsultaJobTypeActive";

    return this.srv.getCall(qst).pipe(
      mergeMap(data => {
        let mydata: any = { success: false, msg: '', val: [] };
        mydata = data;
        //console.log("data:",data);
        //console.log("mydata:",mydata);

        if (mydata.success == true) {
          return of(mydata.val);
        }
        else {
          return of(false);
        }
      }),
      //timeout(MAX_TIME),
      catchError(data => of(false))
    );
  }

  getListFromApi(url) {
    console.log(this.option_processes); // opcion elegida, reporte elegido
    console.log(this.options_ruc); // ruc seleccionado
    console.log(this.options_company); // compania seleccionada
    console.log(this.option_status); // estado seleccionado

    this.isFind = true;
    let list_tmp = [];
    this.srv.getCall(url).subscribe(x => {
      console.log(x);
      if (x.success) {
        if (x.val != null) {
          this.rawdata = x.val;
          console.log('datoss:',this.rawdata)
          let new_list = [];
          list_tmp = x.val;
          let estado = this.option_status == 1 ? 'Habilitado' : 'Inhabilitado';

          //Primera regla, todos, sin compania ni ruc ni fechas solo estado
          if(
            (this.options_ruc == -1 || this.options_ruc == null) &&
            (this.options_company == -1 || this.options_company == null) &&
            (this.date_init == null && this.date_end == null) &&
            (this.option_status == 1 || this.option_status == 2) &&
            (this.option_processes == 5 || this.option_processes == 6)
          ){
            if (this.option_processes == 5) {
              list_tmp.forEach(tmp => {
                if (tmp.Status == estado) {
                  new_list.push(tmp);
                }
              });
            } else if (this.option_processes == 6) {
              list_tmp.forEach(tmp => {
                if (tmp.Estado == estado) {
                  new_list.push(tmp);
                }
              });
            }
            this.rawdata = new_list;
            this.rows = new_list;
          }

          // Filtro por fecha y proceso
          else if ((!isNull(this.date_init) && !isNull(this.date_end)) &&
            (this.options_ruc == -1 || this.options_ruc == null) &&
            (this.options_company == -1 || this.options_company == null) &&
            (this.option_status == -1 || this.option_status == null) &&
            this.option_processes !== null
          ) {
            if (this.option_processes == 1) {
              list_tmp.forEach(tmp => {
                tmp.CreationTime = this.formatOnlyDate(new Date(tmp.CreationTime));
                if (tmp.CreationTime >= this.date_init && tmp.CreationTime <= this.date_end) {
                  new_list.push(tmp);
                }
              });
            } else if (this.option_processes == 2) {
              list_tmp.forEach(tmp => {
                tmp.StartDate = this.formatOnlyDate(new Date(tmp.StartDate));
                if (tmp.StartDate >= this.date_init && tmp.StartDate <= this.date_end) {
                  new_list.push(tmp);
                }
              });
            } else if (this.option_processes == 3) {
              list_tmp.forEach(tmp => {
                tmp.Wa_DateBegin = this.formatOnlyDate(new Date(tmp.Wa_DateBegin));
                if (tmp.Wa_DateBegin >= this.date_init && tmp.Wa_DateBegin <= this.date_end) {
                  new_list.push(tmp);
                }
              });
            } else if (this.option_processes == 4) {
              list_tmp.forEach(tmp => {
                tmp.StartDate = this.formatOnlyDate(new Date(tmp.StartDate));
                if (tmp.StartDate >= this.date_init && tmp.StartDate <= this.date_end) {
                  new_list.push(tmp);
                }
              });
            } else if (this.option_processes == 5) {
              list_tmp.forEach(tmp => {
                if (this.option_processes == null || this.option_processes == -1) {
                  new_list.push(tmp);
                } else if (this.option_status == 1 || this.option_status == 2) {
                  if (tmp.Status == estado) {
                    new_list.push(tmp);
                  }
                }
              });
            } else if (this.option_processes == 6) {
              list_tmp.forEach(tmp => {
                if (this.option_processes == null || this.option_processes == -1) {
                  new_list.push(tmp);
                } else if (this.option_status == 1 || this.option_status == 2) {
                  if (tmp.Estado == estado) {
                    new_list.push(tmp);
                  }
                }
              });
            }
            this.rawdata = new_list;
            this.rows = new_list;

            // Filtro por RUC o compañía y fecha
          } else if ((this.options_ruc != -1 || this.options_company != -1) &&
            (!isNull(this.date_init) && !isNull(this.date_end))
          ) {
            if (this.option_processes == 1) {
              list_tmp.forEach(tmp => {
                tmp.CreationTime = this.formatOnlyDate(new Date(tmp.CreationTime));
                if (tmp.CreationTime >= this.date_init && tmp.CreationTime <= this.date_end) {
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID == this.options_company) {
                    new_list.push(tmp);
                  }
                }
              });
            } else if (this.option_processes == 2) {
              list_tmp.forEach(tmp => {
                tmp.StartDate = this.formatOnlyDate(new Date(tmp.StartDate));
                if (tmp.StartDate >= this.date_init && tmp.StartDate <= this.date_end) {
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID == this.options_company) {
                    new_list.push(tmp);
                  }
                }
              });
            } else if (this.option_processes == 3) {
              list_tmp.forEach(tmp => {
                tmp.Wa_DateBegin = this.formatOnlyDate(new Date(tmp.Wa_DateBegin));
                if (tmp.Wa_DateBegin >= this.date_init && tmp.Wa_DateBegin <= this.date_end) {
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID == this.options_company) {
                    new_list.push(tmp);
                  }
                }
              });
            }
            this.rawdata = new_list;
            this.rows = new_list;

            // Filtro por RUC o compañía sin fecha
          } else if ((this.options_ruc != -1 || this.options_company != -1 )
            && (isNull(this.date_init) && isNull(this.date_end)) ) {
            // console.log('Tercer filtro')
            if (this.isSkretting) {
              // console.log('SKRETTING')
              // console.log(this.options_company, this.options_ruc, this.option_processes);
              if (this.option_processes == 1 && ((this.options_ruc !== null && this.options_ruc != -1) || (this.options_company != null && this.options_company != -1 ))) {
                // console.log('Tercer filtro induccion')
                list_tmp.forEach(tmp => {
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID ==  this.options_company) {
                    new_list.push(tmp);
                  }
                })
                this.rawdata = new_list;
                this.rows = new_list;
              } else if (this.option_processes == 2 && ((this.options_ruc !== null && this.options_ruc != -1) || (this.options_company != null && this.options_company != -1 ))) {
                // console.log('Tercer filtro ingreso')
                list_tmp.forEach(tmp => {
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID ==  this.options_company) {
                    new_list.push(tmp);
                  }
                })
                this.rawdata = new_list;
                this.rows = new_list;
              } else if (this.option_processes == 3 && ((this.options_ruc !== null && this.options_ruc != -1) || (this.options_company != null && this.options_company != -1 ))) {
                // console.log('Tercer filtro trabajo')
                list_tmp.forEach(tmp => {
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID ==  this.options_company) {
                    new_list.push(tmp);
                  }
                })
                this.rawdata = new_list;
                this.rows = new_list;
              } else if (this.option_processes == 5 && ((this.options_ruc !== null && this.options_ruc != -1) || (this.options_company != null && this.options_company != -1 ))) {
                // console.log('Cuarto filtro para reporte de personal')
                if (this.option_status == null || this.option_status == -1) {
                  console.log('entro aqui a sin estado, todos option 5');
                  list_tmp.forEach(tmp => {
                    if (tmp.CompanyID == this.options_ruc || tmp.CompanyID ==  this.options_company) {
                      new_list.push(tmp);
                    }
                  })
                } else if (this.option_status == 1 || this.option_status == 2) {
                  console.log('entro aqui a con estado option 5');
                  list_tmp.forEach(tmp => {
                    if (tmp.CompanyID == this.options_ruc || tmp.CompanyID ==  this.options_company) {
                      if (tmp.Status == estado) {
                        new_list.push(tmp);
                      }
                    }
                  })
                }
                this.rawdata = new_list;
                this.rows = new_list;
              } else if (this.option_processes == 6 && ((this.options_ruc !== null && this.options_ruc != -1) || (this.options_company != null && this.options_company != -1 ))) {
                // console.log('Cuarto filtro para reporte de personal')
                if (this.option_status == null || this.option_status == -1) {
                  console.log('entro aqui a sin estado, todos option 6');
                  list_tmp.forEach(tmp => {
                    if (tmp.CompanyID == this.options_ruc || tmp.CompanyID ==  this.options_company) {
                      new_list.push(tmp);
                    }
                  })
                } else if (this.option_status == 1 || this.option_status == 2) {
                  console.log('entro aqui a con estado option 6');
                  list_tmp.forEach(tmp => {
                    if (tmp.CompanyID == this.options_ruc || tmp.CompanyID ==  this.options_company) {
                      if (tmp.Estado == estado) {
                        new_list.push(tmp);
                      }
                    }
                  })
                }
              }
              else if ((this.option_processes == 1 || this.option_processes == 2 || this.option_processes == 3 || this.option_processes == 4 || this.option_processes == 5 || this.option_processes == 6) && ((this.options_ruc == null || this.options_ruc == -1) || (this.options_company == null || this.options_company == -1) )) {
                // console.log('Tercer filtro cualquiera sin ruc o compañia skretting')
                if (this.option_status == null || this.option_status == -1) {
                  list_tmp.forEach(tmp => {
                    new_list.push(tmp);
                  })
                } else if ((this.option_status == 1 || this.option_status == 2) && this.option_processes == 5) {
                  list_tmp.forEach(tmp => {
                    if (tmp.Status == estado) {
                      new_list.push(tmp);
                    }
                  })
                } else if ((this.option_status == 1 || this.option_status == 2) && this.option_processes == 6) {
                  list_tmp.forEach(tmp => {
                    if (tmp.Estado == estado) {
                      new_list.push(tmp);
                    }
                  })
                }

                this.rawdata = list_tmp;
                this.rows = list_tmp;
              }
            }

            else {
              if ((this.option_processes == 1 || this.option_processes == 2 || this.option_processes == 3 || this.option_processes == 4 || this.option_processes == 5 || this.option_processes == 6) && ((this.options_ruc == null || this.options_ruc == -1) || (this.options_company == null || this.options_company == -1) )) {
                // console.log('Tercer filtro cualquiera sin ruc o compañia contratista')

                if (this.option_status == null || this.option_status == -1) {
                  list_tmp.forEach(tmp => {
                    new_list.push(tmp);
                  })
                } else if ((this.option_status == 1 || this.option_status == 2) && this.option_processes == 5) {
                  list_tmp.forEach(tmp => {
                    if (tmp.Status == estado) {
                      new_list.push(tmp);
                    }
                  })
                } else if ((this.option_status == 1 || this.option_status == 2) && this.option_processes == 6) {
                  list_tmp.forEach(tmp => {
                    if (tmp.Estado == estado) {
                      new_list.push(tmp);
                    }
                  })
                }

                this.rawdata = list_tmp;
                this.rows = list_tmp;
              }
            }
          }

          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          console.log(this.rows);

          this.filterAll();
          this.isFind = false;
          if (this.rows.length === 0) {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          }
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.isFind = false;
          return;
        }
      } else {
        this.isFind = false;
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  rucSelected() {
    if (this.options_ruc == -1 || this.options_ruc == null ) {
      this.disabledCompany = false;
    } else {
      this.disabledCompany = true;
    }
  }

  companySelected() {
    console.log(this.options_company);
    if (this.options_company == -1 || this.options_company == null ) {
      this.disabledRuc = false;
    } else {
      this.disabledRuc= true;
    }
  }

  async downloadReport() {
    // console.log(this.rows)

    Swal.fire(
      {
        title: this.translations['download_report'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    let listInReport = this.rows;
    const pdf = new PdfMakeWrapper();
    let nameDoc = '';
    let title = '';
    let lista_titulos = [];
    let lista_widths = [];
    let nueva_lista_reportes = [];

    if (this.option_processes == 1) {
      nameDoc = 'Solicitud de inducción';
      title = this.translations.msg_rep_induction;
    } else if (this.option_processes == 2) {
      nameDoc = 'Solicitud de ingreso';
      title = this.translations.msg_rep_entry_request;
    } else if (this.option_processes == 3) {
      nameDoc = 'Permiso de trabajo';
      title = this.translations.msg_rep_worker_auth;
    } else if (this.option_processes == 4) {
      nameDoc = 'Indicadores';
      title = this.translations.msg_rep_indicators;
    } else if (this.option_processes == 5) {
      nameDoc = 'Reporte de estado de personal';
      title = this.translations.msg_rep_personal_status;
    } else if (this.option_processes == 6) {
      nameDoc = 'Reporte de personal por compañia';
      title = this.translations.msg_rep_personal_status_company;
    }

    if(this.isSkretting) {
      if (this.option_processes == 1) {
        lista_titulos = [
          new Cell( new Txt(this.translations.name_last_name).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.ci).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.menu_contract).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.ruc).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.menu_subcontract_emp).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.theme_induction).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.date_induction).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.calification_inducton_evaluation).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.status_induction).margin([0,5,0,5]).color('white').bold().end).end,
        ];
        lista_widths = [
          '*','auto','*','auto','*','*','auto','auto','auto','auto'
        ]
      } else if(this.option_processes == 2){
        lista_titulos = [
          new Cell( new Txt(this.translations.name_last_name).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.ci).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.menu_contract).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.ruc).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.menu_subcontract_emp).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.sol_number).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.date_init).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.date_cerr).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.status_docs).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.status_eval).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.status_sanct).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.habilitation).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.colors).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.asist).margin([0,5,0,5]).color('white').bold().end).end,
        ];
        lista_widths = [
          '*','auto','*','auto','*','auto','auto','auto','auto','auto','auto','auto','auto','auto'
        ]
      } else if (this.option_processes == 3) {
        lista_titulos = [
          new Cell( new Txt(this.translations.menu_contract).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.ruc).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.quantity_w_risk).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.quantity_w_auths).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.quantity_w_open).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.quantity_w_closed).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.worker_quantity).margin([0,5,0,5]).color('white').bold().end).end,
        ];
        lista_widths = [
          '*','auto','auto','auto','auto','auto','auto'
        ]
      }  else if(this.option_processes == 4){
        lista_titulos = [
          new Cell( new Txt(this.translations.menu_subcontract_emp).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.year).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.month).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.IncidentsNumber).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.AccidentsNumber).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.PlannedARTNumber).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.ExecutedARTNumber).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.PermitsWorkNumber).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.EnteredPeopleNumber).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.MHWorked).margin([0,5,0,5]).color('white').bold().end).end,
          new Cell( new Txt(this.translations.TalksSchedule).margin([0,5,0,5]).color('white').bold().end).end,

        ];
        lista_widths = [
          '*','auto','*','auto','auto','auto','auto','auto','auto','auto','auto'
        ]
      } else if(this.option_processes == 5) {
        lista_titulos = [
          new Cell(new Txt(this.translations.company).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.workerName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.workerId).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.documentStatus).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.inductionStatus).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.workerStatus).margin([0, 5, 0, 5]).color('white').bold().end).end,
          //new Cell(new Txt(this.translations.colorScale).margin([0, 5, 0, 5]).color('white').bold().end).end
        ];
        lista_widths = [
          '*', 'auto', 'auto', 'auto', 'auto', 'auto',// 'auto'
        ]
      } else if(this.option_processes == 6) {
        lista_titulos = [
          new Cell(new Txt(this.translations.company).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ruc).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell (new Txt(this.translations.cuantityPersonal).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell (new Txt(this.translations.habilitated_personal).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell (new Txt(this.translations.not_habilitated_personal).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell (new Txt(this.translations.status).margin([0, 5, 0, 5]).color('white').bold().end).end
        ];
        lista_widths = [
          '*', 'auto', 'auto', 'auto', 'auto', 'auto'
        ]
      }
    } else {
    if (this.option_processes == 1) {
      lista_titulos = [
        new Cell( new Txt(this.translations.name_last_name).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.ci).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.menu_subcontract_emp).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.theme_induction).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.date_induction).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.calification_inducton_evaluation).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.status_induction).margin([0,5,0,5]).color('white').bold().end).end,
      ];
      lista_widths = [
        '*','auto','*','*','auto','auto','auto'
      ]
    } else if(this.option_processes == 2){
      lista_titulos = [
        new Cell( new Txt(this.translations.name_last_name).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.ci).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.menu_subcontract_emp).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.sol_number).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.date_init).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.date_cerr).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.status_docs).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.status_eval).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.status_sanct).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.habilitation).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.colors).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.asist).margin([0,5,0,5]).color('white').bold().end).end,
      ];
      lista_widths = [
        '*','auto','*','auto','auto','auto','auto','auto','auto','auto','auto','auto'
      ]
    } else if (this.option_processes == 3) {
      lista_titulos = [
        new Cell( new Txt(this.translations.sol_number).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.date_init).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.date_cerr).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.level).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.quantity_w_risk).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.worker_quantity).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.quantity_w_open).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.quantity_w_closed).margin([0,5,0,5]).color('white').bold().end).end,
      ];
      lista_widths = [
        '*','*','*','*','*','*','*','*'
      ]
    } else if(this.option_processes == 4){
      lista_titulos = [
        new Cell( new Txt(this.translations.menu_subcontract_emp).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.YearNumber).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.MonthName).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.IncidentsNumber).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.AccidentsNumber).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.PlannedARTNumber).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.ExecutedARTNumber).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.PermitsWorkNumber).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.EnteredPeopleNumber).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.MHWorked).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.TalksSchedule).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.asist).margin([0,5,0,5]).color('white').bold().end).end,
      ];
      lista_widths = [
        '*','auto','*','auto','auto','auto','auto','auto','auto','auto','auto','auto'
      ]
    } else if(this.option_processes == 5) {
      lista_titulos = [
        new Cell(new Txt(this.translations.workerName).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell(new Txt(this.translations.workerId).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell(new Txt(this.translations.documentStatus).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell(new Txt(this.translations.inductionStatus).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell(new Txt(this.translations.workerStatus).margin([0, 5, 0, 5]).color('white').bold().end).end,
        //new Cell(new Txt(this.translations.colorScale).margin([0, 5, 0, 5]).color('white').bold().end).end
      ];
      lista_widths = [
        '*', 'auto', 'auto', 'auto', 'auto', //'auto'
      ]
    } else if(this.option_processes == 6) {
      lista_titulos = [
        new Cell (new Txt(this.translations.cuantityPersonal).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell (new Txt(this.translations.habilitated_personal).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell (new Txt(this.translations.not_habilitated_personal).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell (new Txt(this.translations.status).margin([0, 5, 0, 5]).color('white').bold().end).end
      ];
      lista_widths = [
        '*', 'auto', 'auto', 'auto'
      ]
    }
  }
    console.log('Lista títulos, LINEA 707', lista_titulos);
    console.log('Lista widths, LINEA 708', lista_widths);
    console.log(listInReport);
    // INFORMATION IN TITLE NAV
    pdf.info({
      title: `${nameDoc}`,
      author: `${this.userInfo.name}`,
      subject: 'Listado',
    });
    //OPTIONS
    pdf.defaultStyle({
      bold: false,
      fontSize: 10,
      alignment:'justify'
    });

    pdf.pageMargins([ 40, 110, 40, 110]);
    pdf.pageOrientation("landscape");
    // pdf.header(await
    //   new Img('../../../../assets/images/Skretting-logo.png')
    //   .width('100')
    //   .margin(20)
    //   .build()
    // )
    pdf.header(
      new Table(
        [
          [
            new Cell(await new Img('../../../../assets/images/Skretting-logo.png').width('160').build()).end,
          ]
        ]
      )
        .margin([0,30,30,0])
        .alignment('right')
        .layout('noBorders')
        .widths('*')
        .end,
    );

    pdf.background(
      await new Img('../../../../assets/images/Skretting-footer.png').absolutePosition(0, 515).width(900).build() //505
    )
    // console.log(title)
    pdf.add(
      [
        new Txt(title).fontSize(14).bold().margin([0,0,0,20]).alignment('center').end,
        new Txt([new Txt(this.translations.date + ': ').bold().end, this.fecha_sin_formato]).fontSize(10).alignment('left').end,
        new Txt([new Txt(this.translations.requested_by + ': ').bold().end,this.userInfo.name]).fontSize(10).alignment('left').end,
        new Txt([new Txt(this.translations.user + ': ').bold().end, this.userInfo.logon]).fontSize(10).alignment('left').margin([0,0,0,20]).end,
        new Table(
          [
            lista_titulos,
            ...await this.converDataToRow(listInReport)
          ]
        ).layout({
          fillColor: (rowIndex: number, node:any, columnIndex: number) => {
            return rowIndex === 0 ? '#f62d51' : '';
          },
        })
          .headerRows(1)
          .dontBreakRows(true)
          .fontSize(8)
          .widths(lista_widths)
          // .widths('*')
          .alignment('center')
          .end,
      ]
    )

    pdf.footer( function(currentPage, pageCount) {
      return new Stack(
        [
          new Cell(new Txt('Skretting Ecuador | Km 4.5 & 6.5 vía Durán - Tambo. Durán - Ecuador | Telf: + 593 4 2598100 + 593 4 2815737').fontSize(8).color('gray').alignment('center').margin([0,10,0,0]).end).end,
          new Cell(new Txt('www.skretting.ec').link('https://www.skretting.com/es-ec/').fontSize(8).color('gray').alignment('center').end).end,
          new Cell(new Txt(' Página ' + currentPage.toString() + ' de ' + pageCount).bold().alignment('right').fontSize(9).color('white').margin([0, 40,20, 0]).end).end
        ]
      ).end
    });

    let nombre= '';
    if (this.option_processes == 1) {
      nombre = 'Reporte de Solicitudes de Inducción';
    } else if (this.option_processes == 2) {
      nombre = 'Reporte de Solicitudes de Ingreso';
    } else if (this.option_processes == 3) {
      nombre = 'Reporte de Permisos de Trabajo';
    } else if (this.option_processes == 4) {
      nombre = 'Reporte de Indicadores';
    }
    pdf.create().download(nombre);
    Swal.close();
  }

  // async converDataToRow(list) {
  //   console.log("Lista de entrada:", list);
  //   let nueva_lista = [];
  //   this.l = [];
  //
  //   if (this.url_report_ !== '' && this.url_report_ !== ' ') {
  //     const response = await this.srv.getCall(this.url_report_).toPromise();
  //     if (response.success && response.val) {
  //       const dataMap = new Map();
  //
  //       // Procesar response.val según option_processes
  //       response.val.forEach(element => {
  //         if (this.option_processes === 1) {
  //           element.RequestDetails.forEach(emp => {
  //             emp.evaluations.forEach(evals => {
  //               const key = `${emp.Name}-${element.RequestId}-${evals.nameEval}`;
  //               if (!dataMap.has(key)) {
  //                 const obj = [
  //                   emp.Name, emp.NumberIdentification, element.Name, element.Ruc,
  //                   emp.NameSubCompany, evals.nameEval,
  //                   evals.lastregistereval ? evals.lastregistereval.creationtime.split('T')[0] : null,
  //                   Number((evals.timeEval / 60).toFixed(2)),
  //                   evals.lastregistereval ? evals.lastregistereval.score : null,
  //                   evals.status_end === 'AP' ? 'APROBADOS' : 'REPROBADOS',
  //                   evals.repeticiones, element.RequestId
  //                 ];
  //                 dataMap.set(key, obj);
  //               }
  //             });
  //           });
  //         } else if (this.option_processes === 2) {
  //           element._employee_status.forEach(emp => {
  //             const key = `${emp.Name}-${element.EntryRequestId}`;
  //             if (!dataMap.has(key)) {
  //               const obj = [
  //                 emp.Name, emp.NumberIdentification, element.NameCompany, element.RucCompany,
  //                 emp.NameSubCompany, element.EntryRequestId, element.StartDate.split('T')[0],
  //                 element.EndDte.split('T')[0], emp.Status_Ducument, emp.Status_Evaluation,
  //                 emp.Status_Faltas === 'SI' ? 'SANCIONADO' : 'NO SANCIONADO',
  //                 (emp.Status_Ducument === 'VALIDADO' && emp.Status_Evaluation === 'APROBADO' && emp.Status_Faltas === 'NO')
  //                   ? 'Habilitado' : 'No Habilitado',
  //                 (emp.Status_Ducument === 'VALIDADO' && emp.Status_Evaluation === 'APROBADO' && emp.Status_Faltas === 'NO')
  //                   ? new Cell(new Txt('').end).fillColor('green').end : new Cell(new Txt('').end).fillColor('red').end,
  //                 (emp.Status_Ducument === 'VALIDADO' && emp.Status_Evaluation === 'APROBADO' && emp.Status_Faltas === 'NO') ? 'Si' : 'No'
  //               ];
  //               dataMap.set(key, obj);
  //             }
  //           });
  //         }
  //       });
  //
  //       // Agregar valores únicos a nueva_lista
  //       dataMap.forEach(value => nueva_lista.push(value));
  //
  //       console.log("Datos generados en nueva_lista (option_processes 1 y 2):", nueva_lista);
  //     } else {
  //       Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
  //       this.isFind = false;
  //       return [];
  //     }
  //   } else {
  //     // Procesamiento adicional si no se tiene URL, para los demás casos de option_processes
  //     let map = new Map();
  //
  //     list.forEach(element => {
  //       let key;
  //       let obj;
  //
  //       if (this.option_processes === 3) {
  //         key = element.CompanyID;
  //         obj = map.get(key) || [
  //           element.Name, element.Ruc, 0, 1, 0, 0, element.QuantityWorker
  //         ];
  //         obj[2] += element.Risk ? 1 : 0;
  //         obj[3] += 1;
  //         obj[4] += element.CloseWorkerAuth === 'A' ? 1 : 0;
  //         obj[5] += element.CloseWorkerAuth === 'C' ? 1 : 0;
  //         obj[6] += element.QuantityWorker;
  //       }
  //       else if (this.option_processes === 4) {
  //         key = element.CompanyID;
  //         obj = [
  //           element.NameCompany, element.YearNumber, element.MonthNumber,
  //           element.IncidentsNumber, element.AccidentsNumber,
  //           element.PlannedARTNumber, element.ExecutedARTNumber,
  //           element.PermitsWorkNumber, element.EnteredPeopleNumber,
  //           element.MHWorked, element.TalksSchedule
  //         ];
  //       }
  //       else if (this.option_processes === 5) {
  //         key = element.CompanyId;
  //         obj = [
  //           element.CompanyName, element.Name, element.NumberIdentification,
  //           element.StatusDocumento, element.StatusEvaluaciones, element.Status
  //         ];
  //       }
  //       else if (this.option_processes === 6) {
  //         key = element.CompanyId;
  //         obj = [
  //           element.CompanyName, element.CompanyRuc, element.CantidadPersonal,
  //           element.CantidadHabilitados, element.CantidadInhabilitados, element.Estado
  //         ];
  //       }
  //
  //       if (key && obj) map.set(key, obj);
  //     });
  //
  //     map.forEach(value => nueva_lista.push(value));
  //     console.log("Datos generados en nueva_lista (option_processes 3 - 6):", nueva_lista);
  //   }
  //
  //   return nueva_lista;
  // }





  async converDataToRow(list) {
    // console.log('entro a convert')
    // console.log(this.url_report_)
    // console.log( this.url_report_)
    let nueva_lista = [];
    this.l = [];
    if (this.url_report_ !== '' && this.url_report_ !== ' ') {
      await this.srv.getCall(this.url_report_).toPromise().then(x => {
        if(x.success){
          if(x.val != null){
            console.log('lissta1',x.val);
            x.val.forEach(element => {
              if (this.option_processes == 1) {
                if (element.RequestDetails.length > 0) {
                  element.RequestDetails.forEach(emp => {
                    if (emp.evaluations.length > 0) {
                      // Para cada empleado, mapeamos solo una entrada en función de las evaluaciones
                      const evaluationsData = emp.evaluations.map(evals => {
                        let obj = Array(10).fill('');
                        obj[0] = emp.Name;
                        obj[1] = emp.NumberIdentification;
                        obj[2] = element.Name;
                        obj[3] = element.Ruc;
                        obj[4] = emp.NameSubCompany;
                        obj[5] = evals.nameEval;
                        obj[6] = evals.lastregistereval ? evals.lastregistereval.creationtime.split('T')[0] : '';
                        obj[7] = evals.lastregistereval ? evals.lastregistereval.score : '';
                        obj[8] = evals.status_end === 'AP' ? 'APROBADOS' : evals.status_end === 'RP' ? 'REPROBADOS' : '';
                        //obj[7] = Number((evals.timeEval / 60).toFixed(2));
                        //obj[10] = evals.repeticiones;
                        obj[9] = element.RequestId;
                        return obj;
                      });
                      // Solo agregamos una entrada por empleado, manteniendo solo un registro único
                      this.l.push(evaluationsData[0]);
                    } else {
                      let obj = Array(10).fill('');
                      obj[0] = emp.Name;
                      obj[1] = emp.NumberIdentification;
                      obj[2] = element.Name;
                      obj[3] = element.Ruc;
                      obj[4] = emp.NameSubCompany;
                      obj[9] = element.RequestId;
                      this.l.push(obj);
                    }
                  });
                } else {
                  let obj = Array(10).fill('');
                  obj[2] = element.Name;
                  obj[3] = element.Ruc;
                  obj[9] = element.RequestId;
                  this.l.push(obj);
                }
              }
              else if (this.option_processes == 2) {
                    if (element._employee_status.length > 0) {
                      element._employee_status.forEach(emp => {
                        let obj = ['','','','','','','','','','','',{},''];
                        obj[0] = emp.Name;
                        obj[1] = emp.NumberIdentification;
                        obj[2] = element.NameCompany;
                        obj[3] = element.RucCompany;
                        obj[4] = emp.NameSubCompany;
                        obj[5] = element.EntryRequestId;
                        obj[6] = element.StartDate.split('T')[0];
                        obj[7] = element.EndDte.split('T')[0];
                        obj[8] = emp.Status_Ducument;
                        obj[9] = emp.Status_Evaluation;
                        obj[10] = (emp.Status_Faltas == 'SI' ? 'SANCIONADO': 'NO SANCIONADO')
                        obj[11] =
                          (emp.Status_Ducument == 'VALIDADO' && emp.Status_Evaluation == 'APROBADO' && emp.Status_Faltas == 'NO')
                            ? 'Habilitado' : 'No Habilitado';
                        // obj[11] = obj[10] == 'Habilitado' ? 'Verde': 'Rojo';
                        obj[12] = obj[11] == 'Habilitado' ? new Cell(new Txt('').end).fillColor('green').end : new Cell(new Txt('').end).fillColor('red').end;
                        obj[13] = obj[11] == 'Habilitado' ? 'Si': 'No';
                        this.l.push(obj);
                      });
                    }
                  }
                })

            list.forEach(element => {
              this.l.forEach(worker => {
                //console.log('as',element)
                if (this.option_processes == 1) {
                  if (element.RequestId == worker[9]) {
                    let scoreEnd = null;debugger;
                    let dateEval = null;
                    const employeeEvaluation = element.listRequestdetail[0].EmployeeEvaluation;
                    if (employeeEvaluation && employeeEvaluation.length > 0) {
                      scoreEnd = employeeEvaluation[0].score_end; // Aquí obtenemos el valor de 'score_end'
                      dateEval = employeeEvaluation[0].date_evaluation;
                    }
                    worker[6] = dateEval? dateEval.split('T')[0].split('-').reverse().join('-'): null;
                    worker[7] = scoreEnd;debugger;
                    worker[8] = scoreEnd >= 60 ? 'APROBADO' : 'REPROBADO';
                    if(this.isSkretting) {
                      nueva_lista.push(
                        [worker[0],worker[1],worker[2],worker[3],worker[4],worker[5],worker[6],worker[7],worker[8]]
                      )
                    } else {
                      nueva_lista.push(
                        [worker[0],worker[1],worker[4],worker[5],worker[6],worker[7],worker[8]]
                      )
                    }
                  }
                } else if (this.option_processes == 2) {
                  if (element.EntryRequestId == worker[5]) {
                    if (this.isSkretting) {
                      nueva_lista.push(
                        [worker[0],worker[1],worker[2],worker[3],worker[4],worker[5],worker[6],worker[7],worker[8],worker[9],worker[10],worker[11],worker[12],worker[13]]
                      )
                    } else {
                      nueva_lista.push(
                        [worker[0],worker[1],worker[4],worker[5],worker[6],worker[7],worker[8],worker[9],worker[10],worker[11],worker[12],worker[13]]
                      )
                    }
                  }
                }
              });
            });
            // console.log(nueva_lista)
          }else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.isFind = false;
            return;
          }
        }else {
          this.isFind = false;
          Swal.fire(x.msg, '', 'error');
          return;
        }
      });
      return nueva_lista;
    } else {
      if (this.option_processes == 3) {
        // console.log(list);
        let map = new Map();
        // console.log(...list)
        list.forEach(element => {
          if (this.isSkretting) {
            // console.log('es skretting')
            // console.log(element.ComapnyID)
            if(!map.has(element.CompanyID)) {
              map.set(element.CompanyID, [
                element.Name,
                element.Ruc,
                element.Risk ? 1: 0,
                1,// cantidad de permisos
                element.CloseWorkerAuth == 'A' ? 1: 0,
                element.CloseWorkerAuth == 'C' ? 1: 0,
                element.QuantityWorker
              ])
            } else {
              let tmp = map.get(element.CompanyID);
              tmp[2] = element.Risk ? tmp[2] + 1 : tmp[2];
              tmp[3] = tmp[3] + 1;
              tmp[4] = element.CloseWorkerAuth == 'A' ? tmp[4] + 1 : tmp[4];
              tmp[5] = element.CloseWorkerAuth == 'C' ? tmp[5] + 1 : tmp[5];
              tmp[6] = tmp[6] + element.QuantityWorker;
            }
          } else {
            // console.log('no es skretting')
            // console.log(element.ComapnyID)
            if(!map.has(element.CompanyID)) {
              map.set(element.CompanyID, [
                element.WorkAuthId,
                element.Wa_DateBegin.split('T')[0],
                element.Wa_DaTeend.split('T')[0],
                element.PlantaName,
                element.Risk ? 1 : 0,
                element.QuantityWorker,
                element.CloseWorkerAuth == 'C' ? 1: 0,
                element.CloseWorkerAuth == 'A' ? 1: 0,
              ])
            } else {
              let tmp = map.get(element.CompanyID);
              tmp[4] = element.Risk ? tmp[4] + 1 : tmp[4];
              tmp[5] = tmp[5] + element.QuantityWorker;
              tmp[6] = element.CloseWorkerAuth == 'C' ? tmp[6] + 1 : tmp[6];
              tmp[7] = element.CloseWorkerAuth == 'A' ? tmp[7] + 1 : tmp[7];
            }
          }
        });
        // console.log(map)
        for (let [key, value] of map) {
          nueva_lista.push(value)
        }
        return nueva_lista
      }
      else if (this.option_processes == 4) {
        // console.log(list);
        let map = new Map();
        // console.log(...list)
        list.forEach(element => {
          if (this.isSkretting) {
            // console.log('es skretting')
            // console.log(element.ComapnyID)
            if(!map.has(element.CompanyID)) {//debugger;
              map.set(element.CompanyID, [
                element.NameCompany,
                element.YearNumber,
                element.MonthNumber,
                //1,// cantidad de permisos
                element.IncidentsNumber,
                element.AccidentsNumber,
                element.PlannedARTNumber,
                element.ExecutedARTNumber,
                element.PermitsWorkNumber,
                element.EnteredPeopleNumber,
                element.MHWorked,
                element.TalksSchedule
              ])
            } else {
              let tmp = map.get(element.CompanyID);
              tmp[2] = element.Risk ? tmp[2] + 1 : tmp[2];
              tmp[3] = tmp[3] + 1;
              tmp[4] = element.CloseWorkerAuth == 'A' ? tmp[4] + 1 : tmp[4];
              tmp[5] = element.CloseWorkerAuth == 'C' ? tmp[5] + 1 : tmp[5];
              tmp[6] = tmp[6] + element.QuantityWorker;
            }
          } else {
            // console.log('no es skretting')
            // console.log(element.ComapnyID)
            if(!map.has(element.CompanyID)) {
              map.set(element.CompanyID, [
                element.WorkAuthId,
                element.Wa_DateBegin.split('T')[0],
                element.Wa_DaTeend.split('T')[0],
                element.PlantaName,
                element.Risk ? 1 : 0,
                element.QuantityWorker,
                element.CloseWorkerAuth == 'C' ? 1: 0,
                element.CloseWorkerAuth == 'A' ? 1: 0,
              ])
            } else {
              let tmp = map.get(element.CompanyID);
              tmp[4] = element.Risk ? tmp[4] + 1 : tmp[4];
              tmp[5] = tmp[5] + element.QuantityWorker;
              tmp[6] = element.CloseWorkerAuth == 'C' ? tmp[6] + 1 : tmp[6];
              tmp[7] = element.CloseWorkerAuth == 'A' ? tmp[7] + 1 : tmp[7];
            }
          }
        });
        // console.log(map)
        for (let [key, value] of map) {
          nueva_lista.push(value)
        }
        return nueva_lista
      } else if (this.option_processes == 5) {
        // console.log(list);
        let map = new Map();
        // console.log(...list)
        list.forEach(element => {
          if (this.isSkretting) {
            // console.log('es skretting')
            // console.log(element.ComapnyID)
            //if(!map.has(element.CompanyId)) {//debugger;
              map.set(element.CompanyId, [
                element.CompanyName,
                element.Name,
                element.NumberIdentification,
                element.StatusDocumento,
                element.StatusEvaluaciones,
                element.Status,
              ])
            //} else {
            //  let tmp = map.get(element.CompanyID);
            //  tmp[2] = element.Risk ? tmp[2] + 1 : tmp[2];
            //  tmp[3] = tmp[3] + 1;
            //  tmp[4] = element.CloseWorkerAuth == 'A' ? tmp[4] + 1 : tmp[4];
            //  tmp[5] = element.CloseWorkerAuth == 'C' ? tmp[5] + 1 : tmp[5];
            //  tmp[6] = tmp[6] + element.QuantityWorker;
            //}
          } else {
            // console.log('no es skretting')
            // console.log(element.ComapnyID)
            map.set(element.CompanyId, [
              element.Name,
              element.NumberIdentification,
              element.StatusDocumento,
              element.StatusEvaluaciones,
              element.Status,
            ])
          }
        });
        // console.log(map)
        for (let [key, value] of map) {
          nueva_lista.push(value)
        }
        return nueva_lista
      } else if (this.option_processes == 6) {
        // console.log(list);
        let map = new Map();
        // console.log(...list)
        list.forEach(element => {
          if (this.isSkretting) {
            // console.log('es skretting')
            // console.log(element.ComapnyID)
            //if(!map.has(element.CompanyId)) {//debugger;
            map.set(element.CompanyId, [
              element.CompanyName,
              element.CompanyRuc,
              element.CantidadPersonal,
              element.CantidadHabilitados,
              element.CantidadInhabilitados,
              element.Estado,
            ])
            //} else {
            //  let tmp = map.get(element.CompanyID);
            //  tmp[2] = element.Risk ? tmp[2] + 1 : tmp[2];
            //  tmp[3] = tmp[3] + 1;
            //  tmp[4] = element.CloseWorkerAuth == 'A' ? tmp[4] + 1 : tmp[4];
            //  tmp[5] = element.CloseWorkerAuth == 'C' ? tmp[5] + 1 : tmp[5];
            //  tmp[6] = tmp[6] + element.QuantityWorker;
            //}
          } else {
            // console.log('no es skretting')
            // console.log(element.ComapnyID)
            map.set(element.CompanyId, [
              element.CantidadPersonal,
              element.CantidadHabilitados,
              element.CantidadInhabilitados,
              element.Estado,
            ])
          }
        });
        // console.log(map)
        for (let [key, value] of map) {
          nueva_lista.push(value)
        }
        debugger
        console.log(nueva_lista)
        return nueva_lista
      }
    }
  }


  getCompanies() {
    let url = GET_ALL_COMPANIES;
    this.srv.getCall(url).subscribe(data => {
      if(data.success) {
        if(data.val !== null){
          let rs = data.val;
          rs.forEach(element => {
            this.companies_list.push({id:element.CompanyID ,name: element.Name});
            this.ruc_list.push({id: element.CompanyID, ruc: element.Ruc});
          })
        }
      }
    })
  }

  formatOnlyDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString());
  }

  formatDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString())
      + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString()) + ' ' +
      (date.getHours() <= 9 ? `0${date.getHours().toString()}`: date.getHours().toString()) + ':' +
      (date.getMinutes() <= 9 ? `0${date.getMinutes().toString()}`: date.getMinutes().toString()) + ':' +
      (date.getSeconds() <= 9 ? `0${date.getSeconds().toString()}`: date.getSeconds().toString());
  }
  async downloadExcel() {

    Swal.fire(
      {
        title: this.translations['download_excel'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();

    this.workbook = new Workbook();
    let nombreXLSX = "";


    if (this.isSkretting) {
      if (this.option_processes == 1) {
        nombreXLSX = "Reporte de Solicitud de Inducciòn.xlsx";
        this.lista_titulos = [
          this.translations.name_last_name,
          this.translations.ci,
          this.translations.menu_contract,
          this.translations.ruc,
          this.translations.menu_subcontract_emp,
          this.translations.theme_induction,
          this.translations.date_induction,
          this.translations.calification_inducton_evaluation,
          this.translations.status_induction
        ];
      } else if (this.option_processes == 2) {
        nombreXLSX = 'Reporte de Soliciud de Ingreso.xlsx';
        this.lista_titulos = [
          this.translations.name_last_name,
          this.translations.ci,
          this.translations.menu_contract,
          this.translations.ruc,
          this.translations.menu_subcontract_emp,
          this.translations.sol_number,
          this.translations.date_init,
          this.translations.date_cerr,
          this.translations.status_docs,
          this.translations.status_eval,
          this.translations.status_sanct,
          this.translations.habilitation,
          this.translations.colors,
          this.translations.asist
        ];


      } else if (this.option_processes == 3) {
        nombreXLSX = 'Reporte de Permiso de Trabajo.xlsx';
        this.lista_titulos = [
          this.translations.menu_contract, this.translations.ruc, this.translations.quantity_w_risk,
          this.translations.quantity_w_auths, this.translations.quantity_w_open,
          this.translations.quantity_w_closed, this.translations.worker_quantity];

      } else if (this.option_processes == 4) {
        nombreXLSX = 'Reporte de Indicadores.xlsx';
        this.lista_titulos = [
          this.translations.menu_subcontract_emp,this.translations.year,this.translations.month,
          this.translations.IncidentsNumber,this.translations.AccidentsNumber,this.translations.PlannedARTNumber,
          this.translations.ExecutedARTNumber,this.translations.PermitsWorkNumber,this.translations.EnteredPeopleNumber,
          this.translations.MHWorked,this.translations.TalksSchedule]
      }
    } else {
      if (this.option_processes == 1) {
        nombreXLSX = "Reporte de Solicitud de Inducciòn.xlsx";
        this.lista_titulos = [
          this.translations.name_last_name,
          this.translations.ci,
          this.translations.menu_subcontract_emp,
          this.translations.theme_induction,
          this.translations.date_induction,
          this.translations.calification_inducton_evaluation,
          this.translations.status_induction
        ];

      } else if (this.option_processes == 2) {
        nombreXLSX = 'Reporte de Soliciud de Ingreso.xlsx';
        this.lista_titulos = [
          this.translations.name_last_name,
          this.translations.ci,
          this.translations.menu_subcontract_emp,
          this.translations.sol_number,
          this.translations.date_init,
          this.translations.date_cerr,
          this.translations.status_docs,
          this.translations.status_eval,
          this.translations.status_sanct,
          this.translations.habilitation,
          this.translations.colors,
          this.translations.asist
        ];

      } else if (this.option_processes == 3) {
        nombreXLSX = 'Reporte de Permiso de Trabajo.xlsx';
        this.lista_titulos = [
          this.translations.OrderNumber, this.translations.dispatch, this.translations.Division, this.translations.Supplier, this.translations.Invoice, this.translations.EmissionDate,
          this.translations.Amount, this.translations.Detail];


      } else if (this.option_processes == 4) {
        nombreXLSX = 'Reporte de Indicadores.xlsx';
        this.lista_titulos = [
          this.translations.menu_subcontract_emp,this.translations.YearNumber,this.translations.MonthName,
          this.translations.IncidentsNumber,this.translations.AccidentsNumber,this.translations.PlannedARTNumber,
          this.translations.ExecutedARTNumber,this.translations.PermitsWorkNumber,this.translations.EnteredPeopleNumber,
          this.translations.MHWorked,this.translations.TalksSchedule];

      } else if (this.option_processes == 5) {
        nombreXLSX = 'Reporte de importacion de agente.xlsx';
        this.lista_titulos = [
          this.translations.Division, this.translations.ProcedureImportline, this.translations.ProvisionedFI, this.translations.Invoice, this.translations.InvoiceDate,
          this.translations.PurchasePrice, this.translations.ItemNumber, this.translations.ItemName, this.translations.TariffHeading, this.translations.Status,
          this.translations.CompanyName]

      } else if (this.option_processes == 6) {
        nombreXLSX = 'Reporte de ISD crédito tributario.xlsx'
        this.lista_titulos = [
          this.translations.CompanyName, this.translations.OrderNumber, this.translations.CustomsAclarance, "DAI", this.translations.Invoice, this.translations.TariffHeading,
          "Tipo de importación", this.translations.QuantityLiquidation]

      } else if (this.option_processes == 8) {
        nombreXLSX = 'Reporte de Gastos de importación.xlsx';
        this.lista_titulos = [
          this.translations.OrderNumber, this.translations.OrderDisplay, this.translations.Division, this.translations.ProvisionedFI, this.translations.Invoice,
          this.translations.InvoiceDate, this.translations.PurchasePrice, this.translations.PlantPrice, this.translations.Supplier, this.translations.ItemName]

      } else if (this.option_processes == 9) {
        nombreXLSX = 'Reporte de Provisión vs Real.xlsx';
        this.lista_titulos = [
          this.translations.OrderNumber,
          this.translations.OrderDisplay,
          this.translations.Division,
          this.translations.Supplier,
          this.translations.ItemName,
          this.translations.EstimatedCost,
          this.translations.RealCost,
          this.translations.EtaReal,
        ]


      } else if (this.option_processes == 10) {
        nombreXLSX = 'Reporte de Llegada por tipo de producto.xlsx'
        this.lista_titulos = [
          this.translations.TypeProduct, this.translations.ItemNumber, this.translations.ItemName, this.translations.GrossWeightDispatched];

      } else if (this.option_processes == 11) {
        nombreXLSX = 'Reporte de Contenedores Asignados.xlsx';
        this.lista_titulos = [
          this.translations.Agent,
          this.translations.ResponsableAgent,
          this.translations.Eta,
          this.translations.EndCas,
          this.translations.PortShipment,
          this.translations.ResponsableSK,
          this.translations.OC,
          this.translations.CompanyName,
          this.translations.ItemNumber,
          this.translations.ItemName,
          this.translations.Packaging,
          this.translations.Bl,
          this.translations.ContainerNumber,
          this.translations.Type,
          this.translations.StampBl,
          this.translations.WeightItem,
          this.translations.WeightItemBl,
          this.translations.DifferenceProductBl,
          this.translations.Observation + " " + this.translations.DifferenceProductBl,
          this.translations.WeightTareBl,
          this.translations.DifferenceBlTare,
          this.translations.WeightItemInStorage,
          this.translations.DifferenceBlTareStorage,
          this.translations.WeightItemOutStorage,
          this.translations.DifferenceMRNOutputPort,
          this.translations.DifferenceBlvsInPort,
          this.translations.Observation,
          this.translations.DateDeparturePort,
          this.translations.HourDeparturePort,
          this.translations.HourInPlant,
          this.translations.TransportCompany,
          this.translations.WarehouseReception,
          this.translations.DateDepartureReception,
          this.translations.HourDepartureReception,
          this.translations.DeparturePort,
          this.translations.Observation,
          this.translations.Reason,
          this.translations.CommentaryOther,
          this.translations.InPlant,
          this.translations.RescheduleDate,
          this.translations.RescheduleHour,
          this.translations.Commentary,
          this.translations.DateInWarehouse,
          this.translations.HourInWarehouse,
          this.translations.WeightInWarehouse,
          this.translations.WeightDifference,
          this.translations.TransportAssign,
          this.translations.EntryToPort,
          this.translations.StampWarehouse,
          this.translations.StampDifferent,
          this.translations.DepartureHour,
          this.translations.DownloadTime,
          this.translations.Commentary,

        ]

      }
    }

    await this.ContainsExcel();
    this.workbook.creator = "Skomex";
    this.workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data]);
      // fs.saveAs(blob, "valor.xlsx");
      // console.log("Nombre:", nombreXLSX);
      fs.saveAs(blob, nombreXLSX);
    })
    Swal.close();
  }
  async ContainsExcel() {
    const sheet = this.workbook.addWorksheet('Sheet');

    sheet.getColumn("A").width = 33;
    sheet.getColumn("B").width = 23;
    sheet.getColumn("C").width = 33;
    sheet.getColumn("D").width = 43;
    sheet.getColumn("E").width = 23;
    sheet.getColumn("F").width = 23;
    sheet.getColumn("G").width = 25;
    sheet.getColumn("H").width = 33;
    sheet.getColumn("I").width = 33;
    sheet.getColumn("J").width = 33;
    sheet.getColumn("K").width = 33;
    sheet.getColumn("L").width = 33;
    sheet.getColumn("M").width = 33;
    sheet.getColumn("N").width = 33;
    sheet.getColumn("O").width = 33;
    sheet.getColumn("P").width = 33;
    sheet.getColumn("Q").width = 33;
    sheet.getColumn("R").width = 33;
    sheet.getColumn("S").width = 33;
    sheet.getColumn("T").width = 33;
    sheet.getColumn("U").width = 33;
    sheet.getColumn("V").width = 33;
    sheet.getColumn("W").width = 33;
    sheet.getColumn("X").width = 33;
    sheet.getColumn("Y").width = 33;
    sheet.getColumn("Z").width = 33;

    // ALINEAR COLUMNAS
    sheet.columns.forEach((column) => {
      column.alignment = { vertical: 'middle', wrapText: true }
      column.alignment = { vertical: 'middle', wrapText: true }
    });

    // establecer los valores de las columnas y del header

    //establecer los header
    const headerRow = sheet.getRow(1);
    console.log("Lista de titulos:", this.lista_titulos);
    headerRow.values = this.lista_titulos;
    // headerRow.values = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    headerRow.font = { bold: true, size: 12 };

    this.dataexcel = [];

    if (this.url_report_ !== '' && this.url_report_ !== ' ') {debugger;
    // Obtener datos del servidor y procesarlos
      await this.srv.getCall(this.url_report_).toPromise().then(x => {debugger;
        if (x.success) {
          if (x.val != null) {
            x.val.forEach(element => {
              // Procesamiento según option_processes
              if (this.option_processes == 1) {
                if (element.RequestDetails.length > 0) {
                  element.RequestDetails.forEach(emp => {
                    if (emp.evaluations.length > 0) {
                      // Mapeamos las evaluaciones en un solo registro por empleado
                      const evaluationData = emp.evaluations.map(evals => {
                        let obj = [
                          emp.Name,
                          emp.NumberIdentification,
                          element.Name,
                          element.Ruc,
                          emp.NameSubCompany,
                          evals.nameEval,
                          evals.lastregistereval ? evals.lastregistereval.creationtime.split('T')[0] : null,
                          //Number((evals.timeEval / 60).toFixed(2)),
                          evals.lastregistereval ? evals.lastregistereval.score : null,
                          //evals.status_end === 'AP' ? 'APROBADOS' : evals.status_end === 'RP' ? 'REPROBADOS' : '',
                          evals.status_end === 'AP' ? 'APROBADOS' : evals.status_end === 'RP' ? 'REPROBADOS' : '',
                          element.RequestId
                        ];
                        return obj;
                      });
                      // Solo agregamos un registro único para cada empleado
                      this.l.push(evaluationData[0]);
                    } else {
                      // Caso sin evaluaciones para el empleado
                      this.l.push([
                        emp.Name,
                        emp.NumberIdentification,
                        element.Name,
                        element.Ruc,
                        emp.NameSubCompany,
                        '',
                        '',
                        '',
                        '',
                        element.RequestId
                      ]);
                    }
                  });
                } else {
                  // Caso sin detalles de solicitud (RequestDetails) en el elemento
                  this.l.push(['', '', element.Name, element.Ruc, '', '', '', '', '', element.RequestId]);
                }
              }
              else if (this.option_processes == 2) {debugger;
                element._employee_status.forEach(emp => {debugger;
                  const estadoHabilitado = emp.Status_Ducument == 'VALIDADO' && emp.Status_Evaluation == 'APROBADO' && emp.Status_Faltas == 'NO' ? 'Habilitado' : 'No Habilitado';
                  //this.colorCelda = (estadoHabilitado == 'Habilitado') ? '00FF00' : 'FF0000';
                  const habilitadoTexto = estadoHabilitado == 'Habilitado' ? 'Si' : 'No';
                  this.l.push([
                    emp.Name,
                    emp.NumberIdentification,
                    element.NameCompany,
                    element.RucCompany,
                    emp.NameSubCompany,
                    element.EntryRequestId,
                    element.StartDate.split('T')[0],
                    element.EndDte.split('T')[0],
                    emp.Status_Ducument,
                    emp.Status_Evaluation,
                    emp.Status_Faltas == 'SI' ? 'SANCIONADO' : 'NO SANCIONADO',
                    estadoHabilitado,
                    '',
                    habilitadoTexto
                  ]);
                });
              }
            });

            // Insertar datos en el Excel
            this.rows.forEach(element => {
              this.l.forEach(dataRow => {
                if (this.option_processes == 1) {
                  if (element.RequestId == dataRow[9]) {
                    let scoreEnd = null;debugger;
                    let dateEval = null;
                    // Verifica que listRequestdetail tenga al menos un elemento y que EmployeeEvaluation no sea nulo
                    if (element.listRequestdetail && element.listRequestdetail.length > 0 && element.listRequestdetail[0].EmployeeEvaluation) {
                      const employeeEvaluation = element.listRequestdetail[0].EmployeeEvaluation;

                      if (employeeEvaluation.length > 0) {
                        scoreEnd = employeeEvaluation[0].score_end; // Aquí obtenemos el valor de 'score_end'
                        dateEval = employeeEvaluation[0].date_evaluation;
                      }
                    }
                    dataRow[6] = dateEval? dateEval.split('T')[0].split('-').reverse().join('-'): null;
                    dataRow[7] = scoreEnd;
                    dataRow[8] = scoreEnd >= 60 ? 'APROBADO' : 'REPROBADO';
                    if (this.isSkretting) {
                      sheet.addRow(
                        [dataRow[0], dataRow[1], dataRow[2], dataRow[3], dataRow[4], dataRow[5], dataRow[6], dataRow[7],dataRow[8]]
                      );  // Agrega cada fila a la hoja
                      console.log('fffggg', dataRow)
                    } else {
                      sheet.addRow(
                        [dataRow[0], dataRow[1], dataRow[4], dataRow[5], dataRow[6], dataRow[7], dataRow[8]]
                      )
                    }
                  }
                }
                else if (this.option_processes == 2) {
                  //console.log('ttt', element.WorkOrder)
                  if (element.EntryRequestId == dataRow[5]) {
                    const estadoHabilitado = dataRow[8] == 'VALIDADO' && dataRow[9] == 'APROBADO' && dataRow[10] == 'NO SANCIONADO' ? 'Habilitado' : 'No Habilitado';
                    const colorCelda = (estadoHabilitado == 'Habilitado') ? '00FF00' : 'FF0000';

                    if (this.isSkretting) {
                      const fila = sheet.addRow(
                        [dataRow[0], dataRow[1], dataRow[2], dataRow[3], dataRow[4], element.WorkOrder, dataRow[6], dataRow[7], dataRow[8], dataRow[9], dataRow[10], dataRow[11], dataRow[12], dataRow[13]]
                      );  // Agrega cada fila a la hoja

                      fila.getCell(13).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: colorCelda }
                      };

                    } else {
                      const fila = sheet.addRow(
                        [dataRow[0], dataRow[1], dataRow[4], element.WorkOrder, dataRow[6], dataRow[7], dataRow[8], dataRow[9], dataRow[10], dataRow[11], dataRow[12], dataRow[13]]
                      );

                      fila.getCell(13).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: colorCelda }
                      };

                    }
                  }
                }
              });
            });
          }else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.isFind = false;
            return;
        }
      }else {
          this.isFind = false;
          Swal.fire(x.msg, '', 'error');
          return;
        }
      });

     }else {
      if (this.option_processes == 3) {
        // console.log(list);
        let map = new Map();

        this.rows.forEach(element => {
          if (this.isSkretting) {
            if (!map.has(element.CompanyID)) {
              map.set(element.CompanyID, [
                element.Name,
                element.Ruc,
                element.Risk ? 1 : 0,
                1,// cantidad de permisos
                element.CloseWorkerAuth == 'A' ? 1 : 0,
                element.CloseWorkerAuth == 'C' ? 1 : 0,
                element.QuantityWorker
              ])
            } else {
              let tmp = map.get(element.CompanyID);
              tmp[2] = element.Risk ? tmp[2] + 1 : tmp[2];
              tmp[3] = tmp[3] + 1;
              tmp[4] = element.CloseWorkerAuth == 'A' ? tmp[4] + 1 : tmp[4];
              tmp[5] = element.CloseWorkerAuth == 'C' ? tmp[5] + 1 : tmp[5];
              tmp[6] = tmp[6] + element.QuantityWorker;
            }
          } else {
            // console.log('no es skretting')
            // console.log(element.ComapnyID)
            if (!map.has(element.CompanyID)) {
              map.set(element.CompanyID, [
                element.WorkAuthId,
                element.Wa_DateBegin.split('T')[0],
                element.Wa_DaTeend.split('T')[0],
                element.PlantaName,
                element.Risk ? 1 : 0,
                element.QuantityWorker,
                element.CloseWorkerAuth == 'C' ? 1 : 0,
                element.CloseWorkerAuth == 'A' ? 1 : 0,
              ])
            } else {
              let tmp = map.get(element.CompanyID);
              tmp[4] = element.Risk ? tmp[4] + 1 : tmp[4];
              tmp[5] = tmp[5] + element.QuantityWorker;
              tmp[6] = element.CloseWorkerAuth == 'C' ? tmp[6] + 1 : tmp[6];
              tmp[7] = element.CloseWorkerAuth == 'A' ? tmp[7] + 1 : tmp[7];
            }
          }
        });
        // console.log(map)
        for (let [key, value] of map) {
          console.log('value:',value)
          sheet.addRow(value);
        }
        //return nueva_lista
      } else if (this.option_processes == 4) {
        // console.log(list);
        let map = new Map();

        this.rows.forEach(element => {
          if (this.isSkretting) {
            // console.log('es skretting')
            // console.log(element.ComapnyID)
            if (!map.has(element.CompanyID)) {//debugger;
              map.set(element.CompanyID, [
                element.NameCompany,
                element.YearNumber,
                element.MonthNumber,
                //1,// cantidad de permisos
                element.IncidentsNumber,
                element.AccidentsNumber,
                element.PlannedARTNumber,
                element.ExecutedARTNumber,
                element.PermitsWorkNumber,
                element.EnteredPeopleNumber,
                element.MHWorked,
                element.TalksSchedule
              ])
            } else {
              let tmp = map.get(element.CompanyID);
              tmp[2] = element.Risk ? tmp[2] + 1 : tmp[2];
              tmp[3] = tmp[3] + 1;
              tmp[4] = element.CloseWorkerAuth == 'A' ? tmp[4] + 1 : tmp[4];
              tmp[5] = element.CloseWorkerAuth == 'C' ? tmp[5] + 1 : tmp[5];
              tmp[6] = tmp[6] + element.QuantityWorker;
            }
          } else {
            // console.log('no es skretting')
            // console.log(element.ComapnyID)
            if (!map.has(element.CompanyID)) {
              map.set(element.CompanyID, [
                element.WorkAuthId,
                element.Wa_DateBegin.split('T')[0],
                element.Wa_DaTeend.split('T')[0],
                element.PlantaName,
                element.Risk ? 1 : 0,
                element.QuantityWorker,
                element.CloseWorkerAuth == 'C' ? 1 : 0,
                element.CloseWorkerAuth == 'A' ? 1 : 0,
              ])
            } else {
              let tmp = map.get(element.CompanyID);
              tmp[4] = element.Risk ? tmp[4] + 1 : tmp[4];
              tmp[5] = tmp[5] + element.QuantityWorker;
              tmp[6] = element.CloseWorkerAuth == 'C' ? tmp[6] + 1 : tmp[6];
              tmp[7] = element.CloseWorkerAuth == 'A' ? tmp[7] + 1 : tmp[7];
            }
          }
        });
        // console.log(map)
        for (let [key, value] of map) {
          console.log('value4:',value)
          sheet.addRow(value);
        }
        //return nueva_lista
      }
    }


    // agregar los datos a las filas
    // const rowsToInsert = sheet.getRows(2, this.rows.length);
    //
    // for (let index = 0; index < rowsToInsert.length; index++) {
    //   const itemData = this.rows[index];
    //   console.log('items111: ',itemData)//obtenemos el item segun el index de la iteraciòn(recorrido)
    //   const row = rowsToInsert[index]; //obtenemos la primera fila segun el index de la iteraciòn(recorrido)
    //   // los valores de itemData seran asignados a row (fila actual en la iteraciòn)
    //
    //   if (this.option_processes == 1) {
    //     row.values = [
    //       itemData.RequestId,
    //       itemData.WhoRequest,
    //       // Aquí agregamos 'namecompany' (solo si isSkretting es true)
    //       this.isSkretting ? itemData.namecompany : null,
    //       itemData.plantaname,
    //       itemData.CreationTime.split('T')[0],
    //       itemData.listRequestdetail.length,
    //       itemData.contadorEvaluations,
    //     ];
    //
    //   } else if (this.option_processes == 2) {
    //     const estadoHabilitado = (itemData.RequestDetails.Status_Ducument == 'VALIDADO' && itemData.RequestDetails.Status_Evaluation == 'APROBADO' && itemData.RequestDetails.Status_Faltas == 'NO')
    //       ? 'Habilitado' : 'No Habilitado';
    //     const colorCelda = estadoHabilitado == 'Habilitado' ? 'green' : 'red';
    //     const habilitadoTexto = estadoHabilitado == 'Habilitado' ? 'Si' : 'No';
    //     if (itemData.RequestDetails.length > 0) {
    //       itemData.RequestDetails.forEach(emp => {
    //         row.values = [
    //           emp.EmployeeName,
    //           emp.NumberIdentification,
    //           itemData.CompanyName,
    //           itemData.CompanyRuc,
    //           emp.namesubcompany,
    //           itemData.EntryRequestId,
    //           itemData.StartDate.split('T')[0],
    //           itemData.EndDte.split('T')[0],
    //           itemData.RequestDetails.Status_Ducument,
    //           itemData.RequestDetails.Status_Evaluation,
    //           itemData.RequestDetails.Status_Faltas == 'SI' ? 'SANCIONADO' : 'NO SANCIONADO',
    //           estadoHabilitado,
    //           {v: '', s: {fill: {fgColor: {rgb: colorCelda === 'green' ? '00FF00' : 'FF0000'}}}},
    //           habilitadoTexto
    //         ];
    //       });
    //     }
    //
    //   } else if (this.option_processes == 3) {
    //     row.values = [
    //       itemData.Name,
    //       itemData.Ruc,
    //       itemData.Risk ? 1: 0,
    //       1,// cantidad de permisos
    //       itemData.CloseWorkerAuth == 'A' ? 1: 0,
    //       itemData.CloseWorkerAuth == 'C' ? 1: 0,
    //       itemData.QuantityWorker
    //     ]
    //
    //   } else if (this.option_processes == 4) {
    //     row.values = [
    //
    //
    //       itemData.NameCompany,
    //       itemData.YearNumber,
    //       itemData.MonthNumber,
    //       itemData.IncidentsNumber,
    //
    //       itemData.AccidentsNumber,
    //       itemData.PlannedARTNumber,
    //       itemData.ExecutedARTNumber,
    //       itemData.PermitsWorkNumber,
    //
    //       itemData.EnteredPeopleNumber,
    //       itemData.MHWorked,
    //       itemData.TalksSchedule,
    //     ];
    //
    //   } else if (this.option_processes == 5) {
    //     row.values = [
    //       itemData.Division,
    //       itemData.ProcedureImportline,
    //       itemData.ProvisionedFI,
    //       itemData.Invoice,
    //       itemData.InvoiceDate == null ? "" : itemData.InvoiceDate.split('T')[0],
    //       itemData.PurchasePrice,
    //       itemData.ItemNumber,
    //       itemData.ItemName,
    //       itemData.TariffHeading,
    //       itemData.Status,
    //       itemData.CompanyName,
    //     ];
    //
    //
    //   } else if (this.option_processes == 6) {
    //     row.values = [
    //
    //     ];
    //
    //
    //   } else if (this.option_processes == 8) {
    //     row.values = [
    //       itemData.OrderNumber,
    //       itemData.OrderDisplay,
    //       itemData.Division,
    //       itemData.ProvisionedFI,
    //       itemData.Invoice,
    //       itemData.InvoiceDate == null ? "" : itemData.InvoiceDate.split('T')[0],
    //       itemData.PurchasePrice,
    //       itemData.PlantPrice,
    //       itemData.CompanyName,
    //       itemData.ItemName,
    //     ];
    //
    //
    //   } else if (this.option_processes == 9) {
    //     row.values = [
    //
    //       itemData.OrderNumber,
    //       itemData.OrderDisplay,
    //       itemData.Division,
    //       itemData.CompanyName,
    //       itemData.ItemName,
    //       itemData.PlantPrice,
    //       itemData.PlantPriceReal,
    //       itemData.EtaReal == null ? "" : itemData.EtaReal.split('T')[0]
    //
    //     ];
    //
    //   } else if (this.option_processes == 10) {
    //     row.values = [
    //       itemData.TypeProduct == null ? "-" : itemData.TypeProduct,
    //       itemData.ItemNumber,
    //       itemData.ItemName,
    //       itemData.GrossWeightDispatched == null ? "0" : itemData.GrossWeightDispatched.toString(),
    //     ];
    //   } else if (this.option_processes == 11) {
    //     row.values = [
    //       itemData.Agent,
    //       itemData.UserIdsNames,
    //       itemData.EtaRequired == null ? "" : itemData.EtaRequired.split('T')[0],
    //       itemData.CAS == null ? "" : itemData.CAS.split('T')[0],
    //       itemData.PortShipment,
    //       itemData.NameResposabilitySK,
    //       itemData.OrderNumber,
    //       itemData.CompanyName,
    //       itemData.ItemNumber,
    //       itemData.ItemName,
    //       itemData.PackagingName,
    //       itemData.Bl,
    //       itemData.ContainerNumber,
    //       itemData.Type,
    //       itemData.BlSeal,
    //       itemData.ProductWeight,
    //       itemData.BlWeight,
    //       itemData.WeightDifferenceProductBl,
    //       itemData.ObservationDifferenceProductBl,
    //       itemData.TareBl,
    //       itemData.WeightBlTare,
    //       itemData.WeightInStorage,
    //       itemData.WeightDifferenceBlTareStorage,
    //       itemData.WeightOutPutStorage,
    //       itemData.WeightDifferenceMRNOutputPort,
    //       itemData.WeightDifferenceBlInPort,
    //       itemData.ObservationDifferenceBlInPort,
    //       itemData.Date == null ? "" : itemData.Date.split('T')[0],
    //       itemData.Hour,
    //       itemData.HourInPlant,
    //       itemData.TransportName,
    //       itemData.WarehousesDiv_Name,
    //       itemData.DateDepartureReception == null ? "" : itemData.DateDepartureReception.split('T')[0],
    //       itemData.HourDepartureReception,
    //       itemData.DeparturePort,
    //       itemData.ObservationAgent,
    //       itemData.Reason,
    //       itemData.ObservationDetailAgent,
    //       itemData.InPlant,
    //       itemData.RescheduleDate == null ? "" : itemData.RescheduleDate.split('T')[0],
    //       itemData.RescheduleHour,
    //       itemData.ObservationAgent,
    //       itemData.DateInWarehouse == null ? "" : itemData.DateInWarehouse.split('T')[0],
    //       itemData.HourInWarehouse,
    //       itemData.WeightInWarehouse,
    //       itemData.WeightDifference,
    //       itemData.CarAssigned,
    //       itemData.EntryToPort == null ? "" : itemData.EntryToPort.split('T')[0],
    //       itemData.SealWarehouse,
    //       itemData.SealDifference,
    //       itemData.DepartureHour,
    //       itemData.DownloadHour,
    //       itemData.ObservationWareHouse,
    //
    //     ];
    //
    //   }
    // }

  }

  openUpdateModal(content: any, obj: any) {
    this.GetTrabajadores(obj.CompanyID);
    this.curr_parameter = 0;
    console.log('objeto: ',obj)
    super.openUpdateModal(content, obj, 'lg');
    this.loadingRequired = true;
    this.GetTypeJob().subscribe(x => {
      this.loadingRequired = false;
      if (x != false) {
        this.row_typejob = x;
      }
    });
  }

  openUpdateModal3(content: any, obj: any) {

    this.curr_parameter = 0;
    let newobj = JSON.parse(JSON.stringify(obj));
    this.u_obj1 = newobj;
    console.log(this.u_obj)
    this.modalService.open(content, { centered: true, size: 'lg' });
    this.loadingRequired = true;
  }



}
